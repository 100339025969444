.agreeToTermsOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 999; /* Ensure it's on top */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .agreeToTermsModal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 90%; /* Responsive width */
    max-width: 700px; /* Maximum width for larger screens */
    margin: 0 20px; /* Margin for smaller screens */
    margin-top: -10vh; /* Adjust this value to move the modal up */
  }
  
.agreeToTermsBtn{
    background-color:#002977;
     padding: 8px 10px;
     border:none;
     color: #fff;
     border-radius: 4px;
     cursor: pointer;
}


  @media (max-width: 910px) {
    .agreeToTermsModal{
      margin-left: 70px; /* Add left margin for small screens */
    }
  }
  