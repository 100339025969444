@media print {
  /* Target only the content you want to print */
  .printable-content {
      width: 100%;  /* A4 width */
      height: auto; /* A4 height */
      margin: 0 auto;
      box-sizing: border-box;
      /* Optional: Scale down content to fit within A4 dimensions */
  }

  /* Optionally apply a global reset */
  * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
  }

  /* Hide any other non-printable content */
  body > *:not(.printable-content) {
      display: none;
  }

    /* Avoid breaking inside elements */
    .avoid-break {
      break-inside: avoid;
    }
}

