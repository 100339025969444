.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiDrawer-root .MuiPaper-root,
.MuiDrawer-root .MuiPaper-root .sideList {
  padding-top: 0;
  padding-bottom: 0;
  color: white;
  background-color: #002977;
  fill: white;
}

.MuiDrawer-root .MuiPaper-root svg {
  padding-top: 0;
  padding-bottom: 0;
  fill: white;
}

.spinner {
  animation: rotator 1.4s linear infinite;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

.path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation:
    dash 1.4s ease-in-out infinite,
    colors 5.6s ease-in-out infinite;
}

@keyframes colors {
  0% {
    stroke: #4285f4;
  }
  25% {
    stroke: #de3e35;
  }
  50% {
    stroke: #f7c223;
  }
  75% {
    stroke: #1b9a59;
  }
  100% {
    stroke: #4285f4;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}

.MuiDataGrid-cell {
  cursor: pointer;
}

.e-pv-signature-appearance .e-checkbox-wrapper label.e-label {
  background-color: #002977;
}

.table-row--main{
  background:#f89d07;
}
.table-row--secondary{
  background:#f5c178;
}
.table-row--main:hover,
.table-row--secondary:hover{
  background:#fff !important;
}

.table-row--main .MuiDataGrid-cell--textLeft,
.table-row--secondary .MuiDataGrid-cell--textLeft{
  font-weight: bold;
}