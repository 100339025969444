body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiDataGrid-columnHeaders,
.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiDataGrid-iconSeparator {
  color: #002977;
  /* background-color: #f89d07; */
}

img.logoClass {
  height: 40px;
  width: 40px;
  margin-left: -10px;
  transition: all 2s ease-in-out;
}

button.e-pv-signature-dialog-height {
  height: 50%;
}
