.react-kanban-column {
  background-color: #f89d07;
  box-shadow:
    0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

section.react-trello-lane header {
  color: #002977;
  font-family: Helvetica;
  font-weight: 700;
  text-transform: uppercase;
}

section.react-trello-lane header textarea {
  color: #002977;
  font-size: 1.75rem;
  font-family: Helvetica;
  font-weight: 700;
  text-transform: uppercase;
}

.react-kanban-column-header {
  color: #002977;
  font-size: 1.75rem;
  font-family: Helvetica;
  font-weight: 700;
  text-transform: uppercase;
  background-color: #f89d07;
}
section.react-trello-lane div {
  width: 100%;
}

.react-kanban-board {
  padding: 0;
}
