.addFromAutofillContactSection{
    position: absolute;
    top: -10px;
    right: -4px;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
  }
  
  .addFromAutofillContactSection:hover {
    /* transform: scale(1.1); */
    background-color: #f1f1f1;
  }












