.e-checkbox-wrapper.e-wrapper {
  display: none;
}

.e-pv-signature-dialog-height > .e-footer-content::before {
  font-size: 12px;
  margin-left: 20px;
  text-align: left;
  display: block;
  text-transform: none;
  content: " NOTE: Signatures will not be saved, when you have signed the document, please download or print it.";
}
